<template>
  <main class="contacts container">
    <div class="contacts__inner container-inner" v-if="settings && !pendingRestaurants && !pendingAbout">
      <div class="contacts__wrapper">
        <div class="contacts__info">
          <h1 class="contacts__title h3">{{ $t('Контакти') }}</h1>
          <template v-if="settings.contacts.phone || settings.contacts.email">
            <div class="contacts__address">
              <div v-if="settings.contacts.phone">
                <BaseSvg
                    name="ico-phone"
                    width="3rem"
                    height="3rem"
                    class="contacts__phone-icon"/>
                <a :href="`tel:${settings.contacts.phone}`">{{
                    settings.contacts.phone
                  }}</a>
              </div>

              <div v-if="settings.contacts.email">
                <BaseSvg
                    name="ico-email"
                    width="3rem"
                    height="3rem"
                    class="contacts__email-icon"/>
                <a :href="`mailto:${settings.contacts.email}`">{{
                    settings.contacts.email
                  }}</a>
              </div>
            </div>
          </template>
          <div v-for="(restaurant, index) in restaurantsData"
               :key="index"
               class="restaurant">
            <br/>
            <h4>{{ restaurant.name }}</h4>
            <div class="contacts__element contacts-item">
              <a :href="`tel:${restaurant.phone_number}`">
                <BaseSvg
                    name="ico-phone"
                    width="2rem"
                    height="2rem"
                    class="contacts-item__icon"/>
                <span class="contacts-item__description">
                  {{ restaurant.phone_number }}
                </span>
              </a>
            </div>
            <div class="contacts__element contacts-item">
              <BaseSvg
                  name="ico-clock"
                  width="2rem"
                  height="2rem"
                  class="contacts-item__icon"/>
              <span class="contacts-item__description">
                {{ restaurant.schedule_start }} - {{ restaurant.schedule_end }}
              </span>
            </div>
          </div>
          <template v-if="socials && socials.length">
            <br/>
            <h4 class="contacts__socials-title">
              {{ $t('Ми в соцмережах') }}
            </h4>
            <div class="contacts__social-list social-list">
              <a
                  v-for="social in socials"
                  :key="social.key"
                  :href="social.link"
                  target="_blank"
                  :title="social.key"
              >
                <BaseSvg :name="social.iconName"
                         width="2.5rem"
                         height="2.5rem"
                         class="social-list__icon"/>
              </a>
            </div>
          </template>

          <MobApps class="contacts__apps"/>
        </div>
        <Gallery :gallery="gallery" :pending="pendingAbout" class="contacts__frame"/>
      </div>
      <form class="contacts__form" @submit.prevent="sendForm">
        <BasePreloader v-if="pendingSend"/>
        <h4 class="contacts__form-label">{{ $t(`Зворотній зв'язок`) }}</h4>
        <div
              class="contacts__form-wrapper">
          <BaseInput
              :label="$t('Ім\'я')"
              v-model="inputs.name.value"
              :error="inputs.name.error"
              class="contacts__input"
          />
          <BaseInput
              label="Email"
              type="email"
              v-model="inputs.email.value"
              :error="inputs.email.error"
              class="contacts__input"
          />
          <BaseInput
              :label="$t('Телефон')"
              v-model="inputs.phone.value"
              :error="inputs.phone.error"
              type="tel"
              :mask="{mask: '+{38} ({\\0}#0)-000-00-00',
                      definitions: {'#': /[1-9]/},
                      unmask: true
                    }"
              class="contacts__input"
          />
          <BaseInput
              :label="$t('Ваше питання')"
              v-model="inputs.text.value"
              :error="inputs.text.error"
              class="contacts__input _textarea"
              textarea
              row="4"
          />
        </div>
        <AlertInline :value="overallErrorsMix" class="_warning"/>
        <button type="submit"
                class="contacts__form-button base-btn"
        >
          {{ $t('Надіслати') }}
        </button>
      </form>

      <ContactsMap class="contacts__map"
                   v-if="restaurantsData && restaurantsData.length"
                   :restaurants="restaurantsData"/>
    </div>
  </main>
</template>

<script>
  import {http} from '@/axios'
  import Gallery from '@/components/Gallery'
  import getSocials from '@/helpers/getSocials'
  import {mapGetters, mapState} from 'vuex'
  import MobApps from '@/components/MobApps'
  import formMixins from '../mixins/formMixins'
  import AlertInline from '@/components/AlertInline'
  import ContactsMap from '@/components/ContactsMap'

  export default {
    mixins: [formMixins],
    components: {
      ContactsMap,
      AlertInline,
      MobApps,
      Gallery
    },
    data() {
      return {
        inputs: {
          name: {
            value: null,
            required: true
          },
          email: {
            value: null
          },
          phone: {
            value: null,
            required: true
          },
          text: {
            value: null,
            required: true
          }
        },
        pendingSend: false,
        pendingAbout: true,
        socials: {},
        gallery: {},
        restaurantsData: [],
        pendingRestaurants: true
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      ...mapGetters('user', ['isLogged']),
    },
    methods: {
      sendForm() {
        this.resetErrorsMix(this.inputs)
        const sendData = this.createSendDataMix(this.inputs)
        if (!sendData) return
        this.pendingSend = true
        http.post('/api/callback', sendData).then(() => {
          this.$vModal.close('call-back')
          this.$vModal.open('message', {
            message: this.$t('Ваше питання успішно відправлено')
          })
          this.setInput(['text', null])
        }).catch(e => {
          this.handlerErrorMix(e, this.inputs)
        }).finally(() => {
          this.pendingSend = false
        })
      },
      loadAbout() {
        this.pendingAbout = true
        this.$store.dispatch('aboutUs/load').then((data) => {
          this.gallery = data.gallery
          this.socials = getSocials(data)
        }).finally(() => {
          this.pendingAbout = false
        })
      },
      loadRestaurants() {
        this.pendingRestaurants = true
        this.$store.dispatch('restaurant/load').then((data) => {
          this.restaurantsData = data
        }).then(() => {
          this.pendingRestaurants = false
        })
      },
      setInput([key, value]) {
        this.inputs[key].value = value
        if (this.inputs[key].error) {
          this.inputs[key].error = null
        }
      },
    },
    created() {
      this.loadRestaurants()
      this.loadAbout()
    },
    mounted() {
      if (this.isLogged) {
        this.$store.dispatch('user/load').then((profile) => {
          if (profile.name) {
            this.setInput(['name', profile.name])
          }
          if (profile.email) {
            this.setInput(['email', profile.email])
          }
          if (profile.phone) {
            this.setInput(['phone', profile.phone.replace(/^380|^\+380|^0/g, '')])
          }
        })
      }
    }
  }
</script>
<style>

</style>
