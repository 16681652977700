<template>
  <div class="modal-contacts">
    <div class="modal-contacts__wrapper">
      <div class="contacts-info">
        <div class="contacts-info__item">
          <BaseSvg
              name="ico-clock"
              width="2rem"
              height="2rem"
              class="contacts-info__item-icon"
          />
          <span class="contacts-info__name">
            <strong>{{ $t('Графік роботи доставки') }}:</strong>
            <span class="contacts-info__value">
              {{ $t('з') }} {{ $get(settings.orders.address_delivery_time, 'start') }} {{ $t('до') }}
              {{ $get(settings.orders.address_delivery_time, 'end') }};
            </span>
          </span>
        </div>
        <div class="contacts-info__item">
          <BaseSvg
              name="ico-delivery-moto"
              width="2rem"
              height="2rem"
              class="contacts-info__item-icon"/>
          <span class="contacts-info__name">
            <strong>{{ $t('Тривалість доставки') }}:</strong>
            <span class="contacts-info__value">
              {{ $get(settings.orders, 'delivery_duration') }} {{ $t('хв') }};
            </span>
          </span>
        </div>
        <div class="contacts-info__item">
          <BaseSvg
              name="ico-buy"
              width="2rem"
              height="2rem"
              class="contacts-info__item-icon"/>
          <div class="contacts-info__name">
            <strong>{{ $t('Вартість доставки') }}:</strong>
            <div class="contacts-info__value">
              <strong class="contacts-info__city">{{ $get(defaultCity, 'name', '') }}:</strong>
              <span class="contacts-info__description">
                {{ $t('мінімальна сума замовлення') }} — <strong>
                {{ $get(defaultCity, 'min_order_price', 0) | formatPrice }}
                {{ $t('грн') }};</strong>
              </span>
              <template v-if="min_free_delivery_price">
                <span class="contacts-info__description">
                  {{ $t('замовлення менше') }} {{ min_free_delivery_price | formatPrice }} {{
                    $t('грн')
                  }} — <strong> {{ $get(defaultCity, 'delivery_price', 0) | formatPrice }} {{ $t('грн') }};</strong>
                </span>
                <span class="contacts-info__description">
                  {{ $t('замовлення понад') }} {{ min_free_delivery_price | formatPrice }} {{
                    $t('грн')
                  }} — <strong class="green">{{ $t('безкоштовно') }};</strong>
                </span>
              </template>
              <span v-else-if="Number($get(defaultCity, 'delivery_price', 0)) === 0" class="contacts-info__description">
                {{ $t('Доставка').toLowerCase() }} — <strong class="green">{{ $t('безкоштовно') }};</strong>
              </span>
              <strong class="contacts-info__city">{{ $t('Інші населені пункти') }}:</strong>
              <span class="contacts-info__description">
                {{ $t('мінімальна сума замовлення') }} — <strong>{{ $t('від') }}
                {{ otherMinOrderPrice | formatPrice }} {{ $t('грн') }} *</strong>
              </span>
              <span class="contacts-info__description">
                {{ $t('мінімальна вартість доставки') }} — <strong>{{ $t('від') }}
                {{ otherMinDeliveryPrice | formatPrice }} {{ $t('грн') }} *</strong>
              </span>
              <span class="contacts-info__description _grey">
                {{ $t('* в залежності від населеного пункту') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex'

  export default {
    computed: {
      ...mapState('settings', ['settings']),
      ...mapState('citiesList', ['citiesList']),
      defaultCity() {
        let list = this.citiesList || null
        if (!list) return null
        let defaultCity = list.find(item => item.is_default_city)
        return defaultCity || list[0]
      },
      otherMinOrderPrice() {
        let list = this.citiesList || null
        if (!list) return 0
        let chekNull = list.findIndex(item => item.min_order_price && +item.min_order_price === 0 )
        if (chekNull >= 0) {
          return 0
        }
        return list.reduce((acc, item) => {
          if (!item.is_default_city && !acc || (+item.min_order_price < acc)) {
            acc = +item.min_order_price
          }
          return acc
        }, 0)
      },
      otherMinDeliveryPrice() {
        let list = this.citiesList || null
        if (!list) return 0
        let chekNull = list.findIndex(item => item.delivery_price && +item.delivery_price === 0 )
        if (chekNull >= 0) {
          return 0
        }
        return list.reduce((acc, item) => {
          if (!item.is_default_city && !acc || (+item.delivery_price < acc)) {
            acc = +item.delivery_price
          }
          return acc
        }, 0)
      },
      min_free_delivery_price() {
        return this.$get(this.defaultCity, 'min_free_delivery_price', 0)
      }
    },
    created() {
      this.$store.dispatch('citiesList/load') //todo change to get it from settings
    }
  }
</script>
