<template>
  <GmapMap ref="mapRef"
           class="contacts-map"
           :zoom="zoomDefault"
           :center="center"
           :options="options">
    <GmapInfoWindow :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="onCloseInfo"/>
    <GmapMarker v-for="(marker, index) in markers"
                :key="index"
                :position="marker.position"
                :clickable="true"
                :icon="{url: markerIcon, scaledSize: {width: 43, height: 43}}"
                @click="toggleInfoWindow(marker,index)"
    />
  </GmapMap>
</template>

<script>
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import windowSizeMixin from '@/mixins/windowSizeMixin'
  import {MAP_STYLES} from '@/constants/map'

  if (!Vue.prototype._mapInstaled) {
    Vue.use(VueGoogleMaps, {
      installComponents: true,
      load: {
        key: process.env.VUE_APP_MAP_KEY,
        libraries: 'places',
      }
    })
    Vue.prototype._mapInstaled = true
  }

  export default {
    name: 'ContactsMap',
    props: {
      restaurants: Array
    },
    mixins: [windowSizeMixin],
    data() {
      return {
        MAP_STYLES,
        infoWindowPos: null,
        infoWinOpen: false,
        infoOptions: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        currentMarkerIndex: null,
        markerIcon: require('@/assets/img/svg/marker.svg'),
      }
    },
    computed: {
      zoomDefault() {
        let zoom = 14
        if (this.windowSizeMixin.width <= process.env.VUE_APP_WINDOW_SIZE_MD) {
          zoom = 13
        }
        if (this.windowSizeMixin.width <= process.env.VUE_APP_WINDOW_SIZE_XS) {
          zoom = 12
        }
        return zoom
      },
      markers() {
        let res = []
        res = this.restaurants.map(item => {
          return {
            ...item,
            position: {
              lat: +item.latitude,
              lng: +item.longitude
            }
          }
        })
        return res
      },
      center() {
        let minMaxLat = [this.markers[0].position.lat, this.markers[0].position.lat]
        let minMaxLng = [this.markers[0].position.lng, this.markers[0].position.lng]

        this.markers.forEach(item => {
          let lat = item.position.lat
          let lng = item.position.lng

          if (lat < minMaxLat[0]) {
            minMaxLat[0] = lat
          }
          if (lat > minMaxLat[1]) {
            minMaxLat[1] = lat
          }

          if (lng < minMaxLng[0]) {
            minMaxLng[0] = lng
          }

          if (lng > minMaxLng[1]) {
            minMaxLng[1] = lng
          }
        })

        let centerLat = (minMaxLat[0] + minMaxLat[1]) / 2
        let centerLng = (minMaxLng[0] + minMaxLng[1]) / 2

        return {lat: centerLat, lng: centerLng}
      },
      options() {
        return {
          styles: MAP_STYLES,
        }
      }
    },
    methods: {
      getInfoContent(item) {
        return `
        <div class="info-window">
          <div class="info-window__title">${item.name}</div>
          <ul class="info-window__list">
           <li>
           <a href="tel:${item.phone_number}"><svg><use xlink:href="#ico-phone"></use></svg>${item.phone_number}</a>
           </li>
           <li><svg><use xlink:href="#ico-clock"></use></svg>${item.schedule_start} - ${item.schedule_end}</li>
          </ul>
        </div>
        `
      },
      onCloseInfo() {
        this.infoWinOpen = false
        this.currentMarkerIndex = null
      },
      toggleInfoWindow: function (marker, index) {
        this.infoWindowPos = marker.position
        this.infoOptions.content = this.getInfoContent(marker)

        //this.$refs.mapRef.$mapPromise.then((map) => {
        //  //map.setCenter(marker.position)
        //  //map.setZoom(16)
        //})

        //check if its the same marker that was selected if yes toggle
        if (this.currentMarkerIndex == index) {
          this.infoWinOpen = !this.infoWinOpen
        }
        //if different marker set infowindow to open and reset current marker index
        else {
          this.infoWinOpen = true
          this.currentMarkerIndex = index
        }
      }
    }
  }
</script>

<style>

</style>